<template>
  <div class="settings">
    <div class="container">
      <div class="settings__title">
        <h3>{{$t('settings.push_not')}}</h3>
      </div>
      <div class="box mg-t-30 pd-25">
        <div class="title-gray mg-b-5">{{$t('settings.subject')}}</div>
        <div class="form-element">
          <input v-model="subject" type="text" class="form-control"/>
        </div>
        <div class="title-gray mg-b-5 mg-t-20">{{$t('settings.content')}}</div>
        <div class="form-element">
          <textarea v-model="content" class="form-control"/>
        </div>
        <div class="flex justify-between align-center mgb-5 mg-t-20">
          <div class="flex-1">
            <div class="title-gray mg-b-5 mg-t-20">{{$t('settings.date')}}</div>
            <div class="form-element with-icon">
              <datepicker v-model="date" :placeholder="'YYYY-MM-DD'" format="YYYY-MM-DD" class="form-control"/>
              <img src="/images/icons/calendar.png" class="form-icon"/>
            </div>
          </div>
          <div>
            <div class="title-gray mg-b-5 mg-t-20">{{$t('settings.time')}}</div>
            <div class="form-element">
              <vue-timepicker :key="forceUpdateTimePicker" v-model="time" :hide-dropdown="true" :minute-interval="5" format="HH:mm"/>
            </div>
          </div>
        </div>
        <div class="title-gray mg-b-5 mg-t-20">{{$t('settings.additional_data')}}</div>
        <div class="form-element">
          <textarea v-model="additional" class="form-control"/>
        </div>
        <div class="title-gray mg-b-10 mg-t-20">{{$t('settings.send_to')}}</div>
        <div class="flex align-center justify-around">
          <button class="btn flex-25" :class="{ 'btn--red-outline': selected !== 1, 'btn--red': selected === 1 }" @click="selectOption(1)">{{$t('settings.user')}}</button>
          <button class="btn flex-25" :class="{ 'btn--red-outline': selected !== 2, 'btn--red': selected === 2 }" @click="selectOption(2)">{{$t('settings.merchant')}}</button>
          <button class="btn flex-25" :class="{ 'btn--red-outline': selected !== 3, 'btn--red': selected === 3 }" @click="selectOption(3)">{{$t('settings.both')}}</button>
        </div>
        <div class="mg-t-30 flex justify-center">
          <button @click="sendData" class="btn btn--red flex-50">{{$t('global.send')}}</button>
        </div>
      </div>
    </div>
    <Loading v-if="isLoading"/>
  </div>
</template>

<script>
import Datepicker from 'vue3-datepicker'
import VueTimepicker from 'vue3-timepicker'
import Loading from '@/components/loading/Loading'
import { Settings } from '@/api/api'
import moment from 'moment-timezone'

export default {
  name: 'PushSettings',
  components: {
    Loading,
    Datepicker,
    VueTimepicker
  },
  data () {
    return {
      selected: 1,
      subject: '',
      content: '',
      additional: '',
      date: null,
      time: '',
      isLoading: false,
      forceUpdateTimePicker: 0
    }
  },
  methods: {
    selectOption (index) {
      this.selected = index
    },
    async sendData () {
      this.isLoading = true

      try {
        await Settings.sendPush({
          subject: this.subject,
          content: this.content,
          target: this.selected,
          additional_data: this.additional,
          date: moment(this.date).tz('UTC').format('YYYY-MM-DD') + ' ' + this.time
        })

        this.selected = 1
        this.subject = ''
        this.content = ''
        this.additional = ''
        this.date = null
        this.time = ''
        this.forceUpdateTimePicker += 1

        this.$toast.success(this.$t('settings.success_push'))
      } catch (e) {
        this.$toast.error(this.$t('settings.failed_push'))
      } finally {
        this.isLoading = false
      }
    }
  }
}
</script>

<style scoped lang="scss">
  @import "style.scss";
</style>
